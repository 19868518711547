import React, { Component } from "react";
import './style.css';
// import AnimatedLogo from '../../_generic/anim-logo';
import CTA from "./cta/script";
import FooterContent from "../../_generic/footer-content/script";
import logo from '../../../assets/design.svg';

class CTASection extends Component {
  render() {
    return (
      <div className="container cta__container">
        <video className="vid__contatiner" autoPlay loop muted>
          {/* First Sample Video: <source src='https://firebasestorage.googleapis.com/v0/b/madscribblesofficial-59360.appspot.com/o/videos%2Fvid1.mp4?alt=media&token=1c89ed9c-84f7-423d-8d39-1a51b85fc66d' type="video/mp4" /> */}
          {/* Compressed Video: <source src='https://firebasestorage.googleapis.com/v0/b/madscribblesofficial-59360.appspot.com/o/videos%2Fbg-vid-cmp.mp4?alt=media&token=525cc4e2-3964-4406-a102-d914fe8b7f8e' type="video/mp4" /> */}
          {/* 20MB Video */}<source src='https://firebasestorage.googleapis.com/v0/b/madscribblesofficial-59360.appspot.com/o/videos%2Fbg-vid-cmp.mp4?alt=media&token=525cc4e2-3964-4406-a102-d914fe8b7f8e' type="video/mp4" />
        </video>
        <div className="cta__content">
          <div className="logo__container">
            {/* <AnimatedLogo loop={false} /> */}
            <a href="/">
              <img src={logo} alt="madscribbles.design" />
            </a>
          </div>
          <div className="heading">
            <h1>WE ARE <span>DEDICATED</span><br></br>TO <span>DESIGN</span></h1>
            <h4>We create experience-focussed, dynamic,<br></br>scalable, and sustainable designs and digital<br></br>products for businesses.</h4>
          </div>
          <div className="cta__comp">
            <CTA />
          </div>
          {/* <div className="cta__footer">
            <FooterContent />
          </div> */}
        </div>
      </div>
    );
  }
}

export default CTASection;