/* eslint-disable no-restricted-globals */
import React, { Component } from "react";
import './style.css';
import askwholes from "../../../assets/images/about/about.jpg";

class AboutSection extends Component {
  constructor (props) {
    super(props);

    onscroll = () => {

      const stickySections = document.querySelectorAll(".sticky");

      stickySections.forEach(section => {
        // console.log(section.parentElement.className);

        const offsetTop = section.parentElement.offsetTop;
        const scrollSection = section.querySelector('.scroll_section');

        let percentage = ((window.scrollY - offsetTop) / window.innerHeight) * 100;
        // percentage = percentage < 0 ? 0 : percentage > 400 ? 400 : percentage;
        scrollSection.style.transform = `translate3d(${-(percentage)}vw, 0, 0)`
      });
    }
  }

  render() {
    const imgs_about = [...Array(5).keys()];
    // const imgs_logo = [...Array(19).keys()];

    return (
      <div className="container about__section">
        <div className="about__header">
          <h1>NOT JUST SCRIBBLES. <br />A LANGUAGE OF EXPRESSION.</h1>
          <p>Nature's scribble is the universe. This universe is keep on evolving. Scribbling the idea itself is half done for an evolving design.</p>
          <div className="about__content">
            <div className="about__img">
                <img src={askwholes} alt="madscribbles.design" />
            </div>
            <div className="about__text">
              <p>We are BFFs from different domains and found a common platform to help businesses around the world through our skills and industry knowledge.</p>
              <p><span>Madscribbles Design Studio</span> where we ideate creative and innovative design that portrays your brand/business.</p>
              <p>We create customer-focussed and sustainable designs for your brand and develop interactive and user-friendly web applications for your business to go online.</p>
              <p>Be it starting a new business or modernizing an old one, you can count on us to bring creative solutions that connect with your target market. Join us on this journey filled with endless possibilities, teamwork, and creativity. </p>
            </div>
          </div>
        </div>
        <div className="sticky_parent">
          <div className="sticky">
            <div className="stickey_header">
              <h1>A JOURNEY TO MADSCRIBBLES DESIGN STUDIO!</h1>
              <p>Three friends' quest to turn passion into profession.</p>
            </div>
            <div className="scroll_section">
              {
                imgs_about.map((element) => {
                  return (
                    <img key={element} src={require("../../../assets/images/about/" + (element + 1).toString() + ".jpg")} alt="" />
                  )
                })
              }
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default AboutSection;