import React, { Component } from "react";
import './style.css';
import FooterSection from "../_generic/footer-section/script";
import AboutSection from "./about-section/script";
// import ProjectsSection from "./projects-section/script";

class AboutPage extends Component {
  render() {
    return (
      <div>
        <AboutSection />
        {/* <ProjectsSection /> */}
        <div className="bottom__footer">
          <FooterSection />
        </div>
      </div>
    );
  }
}

export default AboutPage;