import React, { Component } from "react";
import './style.css';

class ServicesSection extends Component {
  render() {
    return (
      <div className="services__container">
        <div className="staller__box"></div>
        <h2>OUR BLACKBOARD OFFERS TO YOUR BRAND</h2>
        <p>We put life to your scribbles through innovative,<br /> sustainable, and engaging design solutions.</p>
        <div className="services">
          <div className="services__content">
            <div className="service__card">
              <h3>DESIGN</h3>
              <ul className="services__list">
                <li className="service__item">Digital Branding</li>
                <li className="service__item">User Experience Design</li>
                <li className="service__item">User Interface Design</li>
                <li className="service__item">Digital Prototyping</li>
                <li className="service__item">Illustrations and Iconography</li>
              </ul>
            </div>
            <div className="service__desc">
              <p>We are professionals who sculpt your ideas into stunning and expressive designs.</p>
            </div>
          </div>
          <div className="services__content">
            <div className="service__card">
              <h3>DEVELOPMENT</h3>
              <ul className="services__list">
                <li className="service__item">Front-End Development</li>
                <li className="service__item">Web Application</li>
                <li className="service__item">Mobile Application</li>
                <li className="service__item">Desktop Application</li>
              </ul>
            </div>
            <div className="service__desc">
              <p>We help you develop experience-focussed dashing and dynamic designs for your digital products.</p>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default ServicesSection;