import React, { Component } from "react";
import './style.css';
import FooterContent from "../footer-content/script";
import FooterCTA from "../footer-cta/script";
import logo from "../../../assets/logo.svg";
import { Link } from "react-router-dom";

class FooterSection extends Component {
  render() {
    return (
      <div className="container footer__container">
        <div className="footer__content">
          <div className="footer__head">
            <div className="footer__title">
              <h1>WE ARE DEDICATED<br></br>TO DESIGN</h1>
            </div>
            <div className="footer__menu">
              <ul className="footer__menu__list">
                <li className="footer__menu__item">
                  {/* <a href="/" className="footer__menu__link">Home</a> */}
                  <Link to="/" className="footer__menu__link">Home <span>&#8599;</span></Link>
                </li>
                <li className="footer__menu__item">
                  {/* <a href="/about" className="footer__menu__link">About us</a> */}
                  <Link to="/about" className="footer__menu__link">About us <span>&#8599;</span></Link>
                </li>
                <li className="footer__menu__item">
                  {/* <a href="/works" className="footer__menu__link">Portfolio</a> */}
                  <Link to="/works" className="footer__menu__link">Portfolio <span>&#8599;</span></Link>
                </li>
                <li className="footer__menu__item">
                  {/* <a href="/contact" className="footer__menu__link">Contact</a> */}
                  <Link to="/contact" className="footer__menu__link">Contact <span>&#8599;</span></Link>
                </li>
              </ul>
            </div>
          </div>
          <div className="cta__wrapper">
            <div className="promo">
              <div className="logo">
                <a href="/">
                  <img src={logo} alt="madscribbles.design" />
                </a>
              </div>
              <div className="tag__line">
                <p><span>Mad Scribbles Design Studio</span> crafting sustainable digital experiences</p>
              </div>
            </div>
            <div className="cta__option">
              <FooterCTA />
            </div>
          </div>
          <div className="socials__footer">
            <FooterContent />
          </div>
        </div>
      </div>
    );
  }
}

export default FooterSection;