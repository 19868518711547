import React from 'react';
import './style.css';
import {Link} from 'react-router-dom';

const FooterCTA = () => {
  return (
    <div className="cta footer__cta">
      {/* <a href="/contact" className='cta__btn'>GET IN TOUCH</a> */}
      <div className="cta_btn_container">
        <Link to="/contact" className='cta__btn'>GET IN TOUCH <span>&#8599;</span></Link>
      </div>
      <h4>Our design converse<br></br>your brand story!</h4>
    </div>
  )
}

export default FooterCTA