import React, { useEffect } from "react";
import { wrapGrid } from "animate-css-grid";
import "./style.css";
import Card from "./card";

const GridSection = (props) => {
  // console.log(props);
  const card_items = props.images.map((url, index) => 
      <Card key={index} url={url} />
  );

  // const cards = [];
  // const initSize = (ev) => {
  //   console.log(ev.target.parentElement);
  //   cards.concat(ev.target.parentElement);

  //   cards[0].className.toggle("card__expanded");
  // }

  useEffect(() => {
    const grid = document.querySelector(".grid");
    wrapGrid(grid,
              {
                duration: 400,
                stagger: 10,
                easing: "easeInOut"
              }
            );
  });

  return (
    <div className="grid__section">
      <div className="grid" /*onLoad={initSize}*/>
        {card_items}
      </div>
    </div>
  );
};

export default GridSection;
