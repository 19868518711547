import React, { Component } from "react";
import './style.css';
import FooterSection from "../_generic/footer-section/script";
import { LazyLoadImage } from 'react-lazy-load-image-component';
import Masonry, {ResponsiveMasonry} from "react-responsive-masonry";
// import { Link } from "react-router-dom";
class workPage extends Component {
  render() {
    const works = this.props.projects;

    return (
      <div>
        <div className="container work__container">
          <div className="work__header">
            <h1>OUR WORKS. <br />CLIENT'S HAPPINESS. <br />FROM OUR SCRAPBOOK.</h1>
            <p>We proudly present you the finest scribbles that crafted with love.</p>
            {/* <p>We proudly present you the finest scribbles that crafted with love for our clients.</p> */}
          </div>
          <div className="works__grid">
            <ResponsiveMasonry columnsCountBreakPoints={{320: 1, 666: 2, 1024: 3}}>
              <Masonry>
                {
                  works.map((work) => {
                    const ind = Math.floor(Math.random() * work.images.length);

                    return (
                      <div className="work__card" key={work.images[ind]}>
                        <LazyLoadImage src={work.images[ind]} alt={`${work.name}`}/>
                        <div className="work__info">
                          <div className="work__info_caption">
                            {
                              work.name.split("").map((c, index) => {
                                // console.log(c);
                                return (
                                  <div key={index}>
                                    <h1>{c}</h1>
                                  </div>
                                )
                              })
                            }
                          </div>
                          {/* <Link to={`/projects/${work.id}`} className="hyperlink">{`${work.name}`} <span>&#8599;</span></Link> */}
                          {/* <a className='hyperlink' href={`/projects/${work.id}`} >{`${work.name}`} <span>&#8599;</span></a> */}
                        </div>
                      </div>
                    )
                  })
                }
              </Masonry>
            </ResponsiveMasonry>
          </div>
        </div>
        <div className="bottom__footer">
          <FooterSection />
        </div>
      </div>
    );
  }
}

export default workPage;