import React, { Component } from "react";
import { Link } from "react-router-dom";
import './style.css';

class NavSection extends Component {
  constructor(props) {
    super(props);

    this.state = {isToggleOn: this.props.hideNav}

    // This binding is necessary to make `this` work in the callback
    this.handleClick = this.handleClick.bind(this);
  }

  render() {
    // console.log(this.props.hideNav);
    return (
      <div className={this.props.hideNav ? 'container nav__container is-active' : 'container nav__container'} >
        <nav className="menu">
          <ul className="menu__list">
            <li className="menu__item">
              <Link to="/" onClick={this.handleClick} className="menu__link">home</Link>
            </li>
            <li className="menu__item">
              <Link to="/about" onClick={this.handleClick} className="menu__link">about us</Link>
            </li>
            <li className="menu__item">
              <Link to="/works" onClick={this.handleClick} className="menu__link">portfolio</Link>
            </li>
            <li className="menu__item">
              <Link to="/contact" onClick={this.handleClick} className="menu__link">contact</Link>
            </li>
          </ul>
        </nav>
        <div className="nav__footer">
          <div className="nav__socials">
            <ul className="social__list">
              <li className="social__item">
                <a href="https://www.instagram.com/madscribbles_design" className="social__link" target="_blank" rel="noreferrer">instagram</a>
              </li>
              <li className="social__item">
                <a href="https://www.linkedin.com/in/madscribbles-design" className="social__link" target="_blank" rel="noreferrer">linkedin</a>
              </li>
              <li className="social__item">
                <a href="https://www.facebook.com/Madscribbles-Design-Studio-111816628295559" className="social__link" target="_blank" rel="noreferrer">facebook</a>
              </li>
              {/* <li className="social__item">
                <a href="#" className="social__link" target="_blank">twitter</a>
              </li> */}
            </ul>
          </div>
          <div className="trade-mark">
            &#169; madscribbles.design
          </div>
        </div>
      </div>
    );
  }

  handleClick() {
    this.props.func(!this.props.hideNav);
  }

}

export default NavSection;