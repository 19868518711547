import React, { Component } from "react";
import './style.css';
import CTASection from "./cta-section/script";
import ServicesSection from "./services-section/script";
import RecentWorksSection from "./recent-works-section/script";
import FooterSection from "../_generic/footer-section/script";
class HeroPage extends Component {
  render() {
    const projects = this.props.projects;

    return (
      <div className="container hero__container">
        <header className="promo__content">
          <CTASection />
        </header>
        <main className="service__content">
          <div className="right__side">
            <ServicesSection />
            <RecentWorksSection projects={projects}/>
          </div>
        </main>
        <div className="secondary__footer">
          <FooterSection />
        </div>
      </div>
    );
  }
}

export default HeroPage;