import React, {Component} from "react";
import { Routes, Route } from "react-router-dom";
import './App.css';
import './_generic/ham-btn/style.css';

import { initializeApp } from 'firebase/app';
import { getFirestore, collection, getDocs, query } from 'firebase/firestore';
import { getStorage, getDownloadURL, listAll, ref } from 'firebase/storage';

import NavSection from './nav-section/script';

import HeroPage from './hero-page/script';
import AboutPage from './about-page/script';
import WorksPage from './works-page/script';
import ContactPage from './contact-page/script';
import ProjectPage from './project-page/script';

// import withScrollToTop from './_generic/with-scroll-to-top';
// import FirebaseOperations from './firebase/script';

class App extends Component {
  constructor(props) {
    super(props);
    this.state = { isToggleOn: false, projects: [] };

    // This binding is necessary to make `this` work in the callback
    this.onToggleChange = this.onToggleChange.bind(this);
    this.handleClick = this.handleClick.bind(this);
    // this.scrollToTop = this.scrollToTop.bind(this);
  }

  async componentDidMount() {

    // window.addEventListener('scroll', this.scrollToTop); // Add event listener on mount

    const firebaseConfig = {
      apiKey: "AIzaSyAToDRWg1lMzHzGbrl9dhGNuC2o_cXh5e0",
      authDomain: "madscribblesofficial-59360.firebaseapp.com",
      databaseURL: "https://madscribblesofficial-59360-default-rtdb.asia-southeast1.firebasedatabase.app",
      projectId: "madscribblesofficial-59360",
      storageBucket: "madscribblesofficial-59360.appspot.com",
      messagingSenderId: "606215045939",
      appId: "1:606215045939:web:86842728aa60d656d47446",
      measurementId: "G-K543KHMWK5"
    };

    const app = initializeApp(firebaseConfig);
    const db = getFirestore(app);
    const storage = getStorage(app);

    const projectsRef = collection(db, 'projects'); // Replace with your collection name
    const q = query(projectsRef); // Optional: Add query filters if needed

    try {
      const querySnapshot = await getDocs(q);
      const projects = [];

      for (const doc of querySnapshot.docs) {
        const data = doc.data();
        // console.log(data.name);
        const imagesRef = ref(storage, `images/${data.name}`);

        projects.push({ id: doc.id, ...data, images: [] });

        // if (data.display === true) {
          const imageUrls = await this.getProjectImages(imagesRef);
          projects.find((c) => c.id === doc.id).images = imageUrls;
        // }
      }

      this.setState({ projects: projects });
    }
    catch (error) {
      console.error('Error fetching projects:', error);
    }
  }

  // componentWillUnmount() {
  //   window.removeEventListener('scroll', this.scrollToTop); // Remove listener on unmount
  // }

  // scrollToTop() {
  //   window.scrollTo({ top: 0, behavior: 'smooth' }); // Smooth scroll to top
  // }

  // componentDidUpdate(prevProps) {
  //   console.log(window.location.pathname);
  //   if (this.props.location && prevProps.location.pathname !== this.props.location.pathname) {
  //     this.scrollToTop(); // Scroll to top on route change
  //   }
  // }

  async getProjectImages(imagesRef) {
    try {
      const result = await listAll(imagesRef);
      const imagePromises = result.items.map((imageRef) => getDownloadURL(imageRef));
      return await Promise.all(imagePromises);
    }
    catch (error) {
      console.error('Error fetching image URLs:', error);
      return []; // Handle potential errors by returning an empty array
    }
  }

  render() {
    return (
      <div className="container">
        <div className="nav__button" onClick={this.handleClick}>
          <div className={this.state.isToggleOn ? 'btn is-active' : 'btn'}>
            <span></span>
            <span></span>
          </div>
        </div>

        <NavSection func={this.onToggleChange} hideNav={this.state.isToggleOn} />
        <Routes>
          <Route path="/" element={<HeroPage projects={this.state.projects} />} />
          <Route path="/about" element={<AboutPage />} />
          <Route path="/works" element={<WorksPage projects={this.state.projects} />} />
          <Route path="/contact" element={<ContactPage />} />
          <Route path="/projects/:id" element={<ProjectPage projects={this.state.projects} />} />
        </Routes>
      </div>
    );
  }

  onToggleChange(status) {
    this.setState({ isToggleOn: status });
    // console.log(this.state.isToggleOn);
  }

  handleClick() {
    this.setState(prevState => ({
      isToggleOn: !prevState.isToggleOn
    }));
  }
}

export default App;
