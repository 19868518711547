import React, { Component } from "react";
import './style.css';
import FooterSection from "../_generic/footer-section/script";
import GridSection from "../_generic/grid-section/script";

import withRouter from "../_generic/with-router";

class ProjectPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      name: "",
      desc: "",
      images: []
    };    
  }

  componentDidMount() {
    const project = this.props.projects.filter(work => work.id === this.props.params.id)[0];

    this.setState({ name: project.name, desc: project.desc, images: project.images }); // Update state with fetched project data
  }

  render() {
    // console.log(this.state.images);

    return (
      <div>
        <div className="container project__container">
          <div className="project__header">
            <h1>{this.state.name}</h1>
            <p>{this.state.desc}</p>
          </div>
          <GridSection images={this.state.images} />
        </div>
        <div className="bottom__footer">
          <FooterSection />
        </div>
      </div>
    );
  }
}

export default withRouter(ProjectPage);