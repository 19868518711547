import { Component } from "react";
import './style.css';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { Link } from "react-router-dom";

class RecentWorksSection extends Component {

  // getRandomPicks = (range) => {
  //   let runIndex = 0;
  //   const picks = [];

  //   while (picks.length < 3) {
  //     const randomIndex = Math.floor(Math.random() * range);

  //     if (!picks.includes(randomIndex)) {
  //       picks.push(randomIndex);
  //     }

  //     runIndex += 1;
      
  //     if (runIndex === (3 * range)) {
  //       return picks;
  //     }
  //   }

  //   return picks;
  // }

  render() {
    const projects = this.props.projects;
    // console.log(projects.length)
    // const picks = this.getRandomPicks(projects.length);

    return (
        <div className="recents__container">
          <div className="recents__header">
            <h2>OUR WORKS. CLIENT'S HAPPINESS.<br></br>FROM OUR SCRAPBOOK.</h2>
            <p>We proudly presenting you the finest scribbles that crafted with love for our clients.</p>
          </div>
          <div className="recents__list">
            {
              projects.map((proj, index) => {
                // console.log(picks);

                // if (picks.includes(index)) {
                if(index < 3) {
                  const ind = Math.floor(Math.random() * proj.images.length);

                  return (
                  <div className="recent__card" key={proj.images[ind]}>
                    <LazyLoadImage src={proj.images[ind]} alt={`${proj.name}`}/>
                  </div>
                )}
                else {
                  return null;
                }
              })
            }
            {/* <a className='hyperlink' href={"/works"}>Check out our works <span>&#8599;</span></a> */}
            <Link to="/works" className="hyperlink">Check out our works <span>&#8599;</span></Link>
            
          </div>
        </div>
    );
  }
}

export default RecentWorksSection;