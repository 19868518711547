import React, { Component } from "react";
import './style.css';

class FooterContent extends Component {
  render() {
    return (
      <div className="footer__content">
        <div className="socials">
            <ul className="social__list">
              <li className="social__item">
                <a href="https://www.instagram.com/madscribbles_design" className="social__link" target="_blank" rel="noreferrer"><span>&#8599;</span> instagram</a>
              </li>
              <li className="social__item">
                <a href="https://www.linkedin.com/in/madscribbles-design" className="social__link" target="_blank" rel="noreferrer"><span>&#8599;</span> linkedin</a>
              </li>
              <li className="social__item">
                <a href="https://www.facebook.com/Madscribbles-Design-Studio-111816628295559" className="social__link" target="_blank" rel="noreferrer"><span>&#8599;</span> facebook</a>
              </li>
              {/* <li className="social__item">
                <a href="#" className="social__link" target="_blank">twitter</a>
              </li> */}
            </ul>
          </div>
          <div className="trade-mark">
            &#169; madscribbles.design
          </div>
      </div>
    );
  }
}

export default FooterContent;