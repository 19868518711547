import React, { Component } from "react";
import './style.css';
import FooterSection from "../_generic/footer-section/script";
import FormSection from "./form/script";

class ContactPage extends Component {
  render() {
    return (
      <>
        <div className="container contact__container">
          {/* <div className="contact__header">
            <h1>OUR WORKS. <br />CLIENT'S HAPPINESS. <br />FROM OUR SCRAPBOOK.</h1>
            <p>We proudly presenting you the finest scribbles that crafted with love for our clients.</p>
          </div> */}
          <FormSection />
        </div>
        <div className="bottom__footer">
            <FooterSection />
          </div>
      </>
    );
  }
}

export default ContactPage;