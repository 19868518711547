import React, { Component } from 'react'
import { LazyLoadImage } from 'react-lazy-load-image-component';

const toggleCardSize = (ev) => {
  let target = ev.target.parentElement;
  if (target.classList.contains("card"))
  {
    target.classList.toggle("card__expanded");
  }

  let master = target.parentElement;
  master.childNodes.forEach((node) => {
    
    if(node !== target)
    {
      // console.log(`${node.classList},   ${target.classList}`);
      node.classList.remove("card__expanded");
    }
  });
};

class Card extends Component {
  render() {
    return (
      <div className="card" onClick={toggleCardSize}>
        <LazyLoadImage src={this.props.url} className="card__img" alt=""/>
      </div>
    )
  }
}

export default Card;