import React, { Component, createRef } from 'react';
import './style.css';
import emailjs from '@emailjs/browser';

class FormSection extends Component {
  constructor(props) {
    super(props);
    this.state = {agreeingTerms: true, request: ""};

    this.form = createRef();

    this.sendEmail = (e) => {
      e.preventDefault();
  
      emailjs.sendForm('service_540kg5e', 'template_bls1yid', this.form.current, {
          publicKey: 'G4ugpJREyy7AYLzt6',
        })
        .then(
          (result) => {
            console.log(result.text);
            e.target.reset();
            this.resetAgreeWithTerms(true);
          },
          (error) => {
            console.log('FAILED...', error.text);
            this.resetAgreeWithTerms(false);
          },
        );
    };
  
    // this.sendEmail = (e) => {
    //   e.preventDefault();
  
    //   emailjs.sendForm('service_540kg5e', 'template_nul7pzw', this.form.current, 'x586pvp9Lia0U_4UM')
    //     .then((result) => {
    //       console.log(result.text);
    //       e.target.reset(); 
    //     }, (error) => {
    //       console.log(error.text);
    //     });
    // };

    this.setReq = (e) => {
      this.state.request = e.target.value;
      // console.log("Request Set to " + this.state.request);
    }

    this.setAgreeWithTerms = () => {
      this.setState((state) =>({agreeingTerms: !state.agreeingTerms}));
      // console.log(this.state.agreeingTerms);
    }

    this.resetAgreeWithTerms = (status) => {
      document.getElementById('btn_submit').disabled = true;

      if(status) {
        alert("Thanks for reaching Madscribbles Design Studio!\nWe will work together soon (Fingers crossed)...");
      }
    }
  }
  
  render () { 
    return (
      <div className="form__container">
        <div className="form__grid">
          <div className="form__col form__content">
            <div className="form__call_msg">
              <p>Can't wait to work with you...</p>
            </div>
            <div className="form__title">
              <h1>GET IN TOUCH</h1>
            </div>
            <div className="form__usp">
              <h4>Our design converse your brand story!</h4>
            </div>
            <div className="form__mail">
              <div className="mail_icon"></div>
              <a className='hyperlink' href="mailto:jot@madscribbles.design">jot@madscribbles.design</a>
            </div>
            <div className="form__phone">
              <div className="phone_icon"></div>
              <a className='hyperlink' href="tel:+919900892398">+91 99 00 892398</a>
            </div>
          </div>
          <div className="form__col">
            <form ref={this.form} onSubmit={this.sendEmail} className="form__form">
              {/* <div className="form__form_heading">
                <h3>Can't wait to know you...</h3>
              </div> */}
              <div className="form__form_grid">
                <input type="text" name='name' placeholder='Name *' required />
              </div>
              <div className="form__form_grid">
                <input type="email" name='email' placeholder='E-mail *' required />
                <input type="tel" name='phone' placeholder='Phone No. (with country code)' />
              </div>
              <div className="req__container">
                <div className="form__form_heading">
                  <h3>Let's craft a proposal as unique as your ideas.</h3>
                </div>
                <div className="req__list" onChange={this.setReq.bind(this)} >
                  <div className="req__item">
                    <input id='rb1' type="radio" name='req' value='design' />
                    <label htmlFor="rb1" >Design</label>
                  </div>
                  <div className="req__item">
                    <input id='rb2' type="radio" name='req' value='development' />
                    <label htmlFor="rb2">Development</label>
                  </div>
                  <div className="req__item">
                    <input id='rb3' type="radio" name='req' value='design & development' />
                    <label htmlFor="rb3">Both</label>
                  </div>
                </div>
              </div>
              <textarea type="text" name='message' placeholder="Type what's in your mind *" required/>
              <div className="misc__container">
                <div className="form__form_terms">
                  <input id='chk1' type="checkbox" name='agree' onChange={this.setAgreeWithTerms}/>
                  <label htmlFor="chk1" className="form__terms_text">
                    <span>I accept the </span>
                    <a className='hyperlink' href="tnc.html" target="_blank" rel="noopener noreferrer">terms & conditions</a>
                  </label>
                </div>
                <div className="form__form_message"><span>* </span> are mandatory fields</div>
              </div>
              <div className="form__form_submit">
                <input id='btn_submit' type="submit" disabled={this.state.agreeingTerms} value='Submit' />
              </div>
            </form>
          </div>
        </div>
      </div>
    )
  }

}

export default FormSection;